/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

@layer base {
  :root {
    --transparent: 0 0 0 / 0;
    --color-overlay: 29 29 29 / 30%;
    --color-transparent: oklch(0% 0 0 / 0);
    --color-white: oklch(100% 0 0);
    --color-black: oklch(0% 0 0);
    --color-neutral-50: oklch(98.51% 0 0);
    --color-neutral-100: oklch(97.02% 0 0);
    --color-neutral-200: oklch(92.19% 0 0);
    --color-neutral-300: oklch(86.99% 0 0);
    --color-neutral-400: oklch(71.55% 0 0);
    --color-neutral-500: oklch(55.55% 0 0);
    --color-neutral-600: oklch(43.86% 0 0);
    --color-neutral-700: oklch(37.15% 0 0);
    --color-neutral-800: oklch(26.86% 0 0);
    --color-neutral-900: oklch(20.46% 0 0);
    --color-neutral-950: oklch(14.48% 0 0);
    --ds-button-foreground-disabled: var(--color-neutral-500);
    --ds-button-background-disabled: var(--color-neutral-100);
    --ds-button-border-disabled: var(--color-neutral-100);

    --font-sans: 'Proxima Nova';
    --font-serif: 'Georgia';
    --font-mono: 'Menlo';
    --font-headline: 'Loew';
    --font-headline-brand: 'Simple Joys';
  }

  html[lang^='ar'] body[class^='theme-'] {
    --font-sans: 'Sans Arabic';
    --font-headline: 'Sans Arabic';
    --font-headline-brand: 'Massira Pen';
  }

  html[lang^='ko'] body[class^='theme-'] {
    --font-sans: 'Yd Gothic';
    --font-headline: 'Yd Gothic';
    --font-headline-brand: 'Nanum Pen';
  }

  html[lang^='th'] body[class^='theme-'] {
    --font-sans: 'Neue Frutiger';
    --font-headline: 'Neue Frutiger';
    --font-headline-brand: 'Neue Frutiger';
  }

  html[lang^='zh'] body[class^='theme-'] {
    --font-headline: 'Fz Chao Cu Hei';
  }

  html[lang^='ru'] body[class^='theme-'] {
    --font-sans: 'Proxima Nova Cyrillic';
  }
}
