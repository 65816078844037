/* Brand- Cobrand */

/* ============================= TYPOGRAPHY =============================*/

@font-face {
  font-family: Biennale;
  font-weight: 400;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/regular.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 200;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/light.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 500;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/medium.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 600;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/semiBold.woff2') format('woff2');
}

@font-face {
  font-family: Biennale;
  font-weight: 700;
  font-display: swap;
  src: url('/modules/assets/fonts/biennale/bold.woff2') format('woff2');
}

/* ============================= THEMING ============================= */

.theme-cb {
  --color-primary: 0 84 80;
  --color-primary-alt: 0 84 80;
  --color-secondary: 11 37 56;
  --color-secondary-alt: 220 226 232;
  --color-brand-alt: 10 57 113; /* global primary-alt */
  --font-headline: 'Biennale';
  --color-text-heading: var(--color-primary);
  --heading-spacing-preset: normal;

  /* Image Headliner styles */
  --image-headliner-control-color-default: var(--color-text-inverse);
}

.theme-cb h1,
.theme-cb h2,
.theme-cb h3,
.theme-cb h4,
.theme-cb h5,
.theme-cb h6 {
  font-variant-ligatures: common-ligatures;
}
