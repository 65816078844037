@tailwind components;

@layer components {
  .surface-base,
  .surface-alternate,
  .surface-inverse {
    color: var(--ds-color-text);
    background-color: var(--ds-color-background);
  }

  .surface-base,
  .surface-alternate {
    --ds-color-background: var(--color-white);
    --ds-color-background-disabled: var(--color-neutral-100);
    --ds-color-border: var(--color-neutral-300);
    --ds-color-border-input: var(--color-neutral-500);
    --ds-color-headline: var(--color-neutral-900);
    --ds-color-text: var(--color-neutral-950);
    --ds-color-text-muted: var(--color-neutral-600);
    --ds-color-text-disabled: var(--color-neutral-400);
    --ds-color-interactive: var(--color-neutral-900);
    --ds-color-interactive-hover: var(--color-neutral-600);

    --ds-button-default-foreground: var(--color-white);
    --ds-button-default-foreground-hover: var(--color-white);
    --ds-button-default-background: var(--color-neutral-900);
    --ds-button-default-background-hover: var(--color-black);
    --ds-button-default-border: var(--color-neutral-900);
    --ds-button-default-border-hover: var(--color-black);

    --ds-button-secondary-foreground: var(--color-white);
    --ds-button-secondary-foreground-hover: var(--color-white);
    --ds-button-secondary-background: var(--color-neutral-600);
    --ds-button-secondary-background-hover: var(--color-neutral-800);
    --ds-button-secondary-border: var(--color-neutral-600);
    --ds-button-secondary-border-hover: var(--color-neutral-800);

    --ds-button-outline-foreground: var(--color-neutral-950);
    --ds-button-outline-foreground-hover: var(--color-white);
    --ds-button-outline-background: var(--color-transparent);
    --ds-button-outline-background-hover: var(--color-neutral-950);
    --ds-button-outline-border: var(--color-neutral-950);
    --ds-button-outline-border-hover: var(--color-neutral-950);
  }

  .surface-alternate {
    --ds-color-background: var(--color-neutral-50);
    --ds-color-border: var(--color-neutral-500);
    --ds-color-border-input: var(--color-neutral-600);
    --ds-color-text-muted: var(--color-neutral-700);
    --ds-color-text-disabled: var(--color-neutral-500);
  }

  .surface-inverse {
    --ds-color-background: var(--color-neutral-900);
    --ds-color-background-disabled: var(--color-neutral-500);
    --ds-color-border: var(--color-black);
    --ds-color-border-input: var(--color-neutral-700);
    --ds-color-headline: var(--color-white);
    --ds-color-text: var(--color-white);
    --ds-color-text-muted: var(--color-neutral-400);
    --ds-color-text-disabled: var(--color-neutral-700);
    --ds-color-interactive: var(--color-white);
    --ds-color-interactive-hover: var(--color-neutral-400);

    --ds-button-default-foreground: var(--color-neutral-950);
    --ds-button-default-foreground-hover: var(--color-black);
    --ds-button-default-background: var(--color-white);
    --ds-button-default-background-hover: var(--color-neutral-100);
    --ds-button-default-border: var(--color-white);
    --ds-button-default-border-hover: var(--color-neutral-100);

    --ds-button-secondary-foreground: var(--color-neutral-950);
    --ds-button-secondary-foreground-hover: var(--color-black);
    --ds-button-secondary-background: var(--color-neutral-300);
    --ds-button-secondary-background-hover: var(--color-neutral-400);
    --ds-button-secondary-border: var(--color-neutral-300);
    --ds-button-secondary-border-hover: var(--color-neutral-400);

    --ds-button-outline-foreground: var(--color-white);
    --ds-button-outline-foreground-hover: var(--color-neutral-950);
    --ds-button-outline-background: var(--color-transparent);
    --ds-button-outline-background-hover: var(--color-white);
    --ds-button-outline-border: var(--color-white);
    --ds-button-outline-border-hover: var(--color-white);
  }

  .theme-link {
    @apply motion-safe:transition-colors;
    color: var(--ds-color-interactive);
    &:hover,
    &:focus,
    &:active {
      color: var(--ds-color-interactive-hover);
    }
  }

  .theme-heading {
    @apply font-headline;
    color: var(--ds-color-headline);
  }

  .theme-btn {
    @apply motion-safe:transition-colors border;
  }

  .theme-btn--default {
    color: var(--ds-button-default-foreground);
    background-color: var(--ds-button-default-background);
    border-color: var(--ds-button-default-border);
    &:hover,
    &:focus,
    &:active {
      color: var(--ds-button-default-foreground-hover);
      background-color: var(--ds-button-default-background-hover);
      border-color: var(--ds-button-default-border-hover);
    }
  }

  .theme-btn--secondary {
    color: var(--ds-button-secondary-foreground);
    background-color: var(--ds-button-secondary-background);
    border-color: var(--ds-button-secondary-border);
    &:hover,
    &:focus,
    &:active {
      color: var(--ds-button-secondary-foreground-hover);
      background-color: var(--ds-button-secondary-background-hover);
      border-color: var(--ds-button-secondary-border-hover);
    }
  }

  .theme-btn--outline {
    color: var(--ds-button-outline-foreground);
    background-color: var(--ds-button-outline-background);
    border-color: var(--ds-button-outline-border);
    &:hover,
    &:focus,
    &:active {
      color: var(--ds-button-outline-foreground-hover);
      background-color: var(--ds-button-outline-background-hover);
      border-color: var(--ds-button-outline-border-hover);
    }
  }

  .theme-btn:disabled {
    @apply shadow-none cursor-not-allowed;
    color: var(--ds-button-foreground-disabled);
    background-color: var(--ds-button-background-disabled);
    border-color: var(--ds-button-border-disabled);
  }
}
